import { CreateControllerFn } from '@wix/yoshi-flow-editor';

type ProductIdentifiers = {
  sku: string;
};

const createController: CreateControllerFn = async ({ controllerConfig }) => {
  const { wixCodeApi } = controllerConfig;

  const openCompareLightbox = (productId: string): boolean => {
    wixCodeApi.window.openLightbox('add-to-compare', { productId });
    return true;
  };

  const getProductSiblings = async (productId: string) => {
    try {
      // @ts-expect-error 'data' is coming in runtime
      const wixData = wixCodeApi.data;

      const results = await wixData
        .query('Products1')
        .include('siblings')
        .eq('storeProduct', productId)
        .limit(1)
        .find();

      if (results._items.length > 0) {
        const siblings = results._items[0].siblings;

        const siblingDataPromises = siblings.map(async (sibling: any) => {
          const siblingResult = await wixData
            .query('Stores/Products')
            .eq('_id', sibling.storeProduct)
            .limit(1)
            .find();
          return siblingResult.items[0];
        });

        const detailedSiblings = await Promise.all(siblingDataPromises);

        console.log('Detailed sibling data:', detailedSiblings);

        controllerConfig.setProps({
          siblings: detailedSiblings,
        });

        return detailedSiblings;
      }
      return [];
    } catch (error) {
      console.error('Failed to get product siblings:', error);
      return [];
    }
  };

  return {
    async pageReady() {
      controllerConfig.setProps({
        onCompareButtonClick: openCompareLightbox,
      });
    },
    exports: () => ({
      set productId(value: string) {
        controllerConfig.setProps({
          productId: value,
        });
        getProductSiblings(value);
      },
      set productIdentifiers(value: ProductIdentifiers) {
        controllerConfig.setProps({
          sku: value.sku,
        });
      },
    }),
  };
};

export default createController;
